import React from "react";
import { Link } from "gatsby";
import { Container, Row, Col } from "react-bootstrap";

import { Section, Button, Title, Text, Box } from "../components/Core";
import PageWrapper from "../components/PageWrapper";
import CTA from "../sections/common/CTA";
import imgWorkCover from "../assets/image/jpg/details/details-2.jpg";
import tjappCover from "../assets/image/tjapp/tjapp-cover.png";
import recipeList from "../assets/image/tjapp/recipe-list.png";
import addMoreIngredients from "../assets/image/tjapp/add-more-ingredients.png";
import emptyList from "../assets/image/tjapp/empty-list.png";
import addIngredient from "../assets/image/tjapp/add-ingredient.png";
import signup from "../assets/image/tjapp/signup.png";
import login from "../assets/image/tjapp/login.png";

import fullRecipe from "../assets/image/tjapp/full-recipe.png";
import fullRecipe2 from "../assets/image/tjapp/full-recipe-2.png";
import addCover from "../assets/image/tjapp/add-cover.png";

import imgS1 from "../assets/image/jpg/details/details-12.jpg";
import imgS2 from "../assets/image/jpg/details/details-11.jpg";
import imgS3 from "../assets/image/jpg/details/details-10.jpg";
import imgS4 from "../assets/image/jpg/details/details-9.jpg";
import imgS5 from "../assets/image/jpg/details/details-14.jpg";
import imgS6 from "../assets/image/jpg/details/details-13.jpg";
import { device } from "../utils";
import List from "../components/Core/List";

const WorkSingle = () => {
  return (
    <>
      <PageWrapper>
        <Section hero>
          <Container>
            <Row className="justify-content-center">
              <Col lg="8">
                <Box>
                  <Text variant="tag">LIFE-STYLE</Text>
                  <Title className="my-4 text-center">
                    Tjapp <br /> The Social Recipe App.
                  </Title>
                  <Text
                    variant="p"
                    css={`
                      max-width: 750px;
                    `}
                  >
                    <br/>
                    Tjapp is a social recipe app that helps you keep track of your favorite recipes. The idea for the
                    app is born from
                    an own need. During our grown-up lives, the question of <strong>"what do we eat
                    tonight?"</strong> arises sooner or later in the day.
                    <br/>
                    <br/>
                    Many times you come up with a good answer, but after a while you forget what it was. The app is
                    meant as a way of keeping track of <strong>your</strong> recipes, the ones you've made before and
                    loved. For me
                    this often means an adaption of an existing recipe I've found somewhere online. I do keep
                    screenshots
                    of these recipes, but I often forget to save the link to the original recipe.

                    <br/><br/>
                    This is where Tjapp comes in. The app helps you keep track of your recipes with a dedicated UI
                    that is built for adding ingredients and the steps to make the recipe.
                    <br/><br/>
                    The power will come from the fact that you can share your recipes with your friends and family and
                    they can do the same. This way you can keep track of your favorite recipes and share them too!
                    <br/><br/>
                    In the past I've been working with React Native, but as the technology progressed, I've been
                    looking for a way to keep my skills up-to-date. Therefore I've combined my wish for a good app where
                    I can simply keep track of my recipes with the wish to improve my skills in React Native.
                    <br/>
                    The app is built using React Native, NodeJS and Postgres. The app is currently in development and
                    will be released soon.
                  </Text>

                  <Row>
                    <Col lg="8">
                      <Box>
                        <Text className="pt-4 pb-2" css={`font-weight: 800`} variant="h4">
                          Experiences gained here:
                        </Text>

                        <List>
                          <li>Deepening my knowledge of NodeJS & Express.</li>
                          <li>Typescript build tool: SWC</li>
                          <li>Write tests for NodeJS</li>
                          <li>Deepening my knowledge of Typescript</li>
                          <li>Continuing my journey with React Native and building apps</li>
                          <li>Improving on using Expo.</li>
                        </List>

                        <Text className="py-2" variant="h5">
                          Any further questions? I'd love to show it to you!
                        </Text>
                      </Box>
                    </Col>
                  </Row>
                </Box>
              </Col>

            </Row>
            <Box pt={["4rem", null, null, "6.25rem"]}>
              <img src={tjappCover} alt="" className="img-fluid w-100" />

              <Box className="flex-wrap grid-gap-2 mt-4">
                <img src={login} alt="" className="px-2 py-2 w-50" />
                <img src={signup} alt=""  className="px-2 py-2 w-50" />
                <img src={emptyList} alt="" className="px-2 py-2 w-50" />
                <img src={recipeList} alt="" className="px-2 py-2 w-50" />
                <img src={addIngredient} alt="" className="px-2 py-2 w-50" />
                <img src={addMoreIngredients} alt="" className="px-2 py-2 w-50" />
                <img src={fullRecipe} alt="" className="px-2 py-2 w-50" />
                <img src={fullRecipe2} alt="" className="px-2 py-2 w-50" />
                <img src={addCover} alt="" className="px-2 py-2 w-50" />
              </Box>
            </Box>
          </Container>
        </Section>

        <div className="mt-lg-3">
          <Container>
            <Row>
              <Col lg="4" className="mb-4 mb-lg-0">
                <Text variant="tag">Client</Text>
                <Title variant="cardBig" className="mt-3">
                  TJAPP
                </Title>
              </Col>
              <Col lg="4" className="mb-4 mb-lg-0">
                <Text variant="tag">Start - Until</Text>
                <Title variant="cardBig" className="mt-3">
                  Jan. 2022 - Present
                </Title>
              </Col>
              {/*<Col lg="4" className="d-flex justify-content-lg-end">*/}
              {/*  <Button arrowRight>Live work</Button>*/}
              {/*</Col>*/}
            </Row>
          </Container>
        </div>

        <Box py={4}>
          <CTA />
        </Box>
      </PageWrapper>
      {/*<Section className="mt-lg-5 pb-0">*/}
      {/*  <Container>*/}
        {/*    <Row>*/}
        {/*      <Col xs="12" className="mb-5">*/}
        {/*        <img src={imgS1} alt="" className="img-fluid w-100" />*/}
        {/*      </Col>*/}
        {/*      <Col xs="12" className="mb-5">*/}
        {/*        <img src={imgS2} alt="" className="img-fluid w-100" />*/}
        {/*      </Col>*/}
        {/*      <Col lg="6" className="mb-5 mb-lg-0 pr-lg-4">*/}
        {/*        <img src={imgS3} alt="" className="img-fluid w-100" />*/}
        {/*      </Col>*/}
        {/*      <Col lg="6" className="mb-5 pl-lg-4">*/}
        {/*        <img src={imgS4} alt="" className="img-fluid w-100" />*/}
        {/*      </Col>*/}
        {/*    </Row>*/}
        {/*  </Container>*/}
        {/*</Section>*/}

        {/*<Section bg="dark" className="pt-0">*/}
        {/*  <Container>*/}
        {/*    <Row>*/}
        {/*      <Col xs="12" className="mb-5">*/}
        {/*        <img src={imgS5} alt="" className="img-fluid w-100" />*/}
        {/*      </Col>*/}
        {/*      <Col xs="12" className="mb-5">*/}
        {/*        <img src={imgS6} alt="" className="img-fluid w-100" />*/}
        {/*      </Col>*/}
        {/*    </Row>*/}

        {/*    <div className="text-center mt-lg-5">*/}
        {/*      <Text variant="tag" className="mb-1" color="lightShade">*/}
        {/*        Next Project*/}
        {/*      </Text>*/}
        {/*      <Link to="portfolio-details">*/}
        {/*        <Button*/}
        {/*          arrowRight*/}
        {/*          className="border-0 bg-transparent shadow-none text-capitalize py-3"*/}
        {/*          css={`*/}
        {/*            font-weight: 700*/}
        {/*            font-size: 1.5rem;*/}
        {/*            letter-spacing: -1.2px;*/}
        {/*            line-height: 1.375;*/}
        {/*            @media ${device.md} {*/}
        {/*              font-size: 2rem;*/}
        {/*            }*/}
        {/*        `}*/}
        {/*        >*/}
        {/*          Replace*/}
        {/*        </Button>*/}
        {/*      </Link>*/}
        {/*    </div>*/}
        {/*  </Container>*/}
        {/*</Section>*/}
    </>
  );
};
export default WorkSingle;
